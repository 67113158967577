import api from "../api/Api";

const Reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN":
      if (payload.platform) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.platform.token}`;
        localStorage.setItem("platform", JSON.stringify(payload.platform));
      }
      if (payload.partition) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.partition.token}`;
        localStorage.setItem("partition", JSON.stringify(payload.partition));
      }
      if (payload.distributor) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.distributor.token}`;
        localStorage.setItem(
          "distributor",
          JSON.stringify(payload.distributor)
        );
      }
      if (payload.store) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.store.token}`;
        localStorage.setItem("store", JSON.stringify(payload.store));
      }
      if (payload.kiosk) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.kiosk.token}`;
        localStorage.setItem("kiosk", JSON.stringify(payload.kiosk));
      }

      return {
        ...state,
        platform: payload.platform,
        partition: payload.partition,
        distributor: payload.distributor,
        store: payload.store,
        kiosk: payload.kiosk,
      };

    case "LOGIN_FAIL":
      return {
        ...state,
        token: null,
        user: null,
        errorMessage: "Login failed",
      };

    case "PLATFORM_LOGOUT":
      localStorage.removeItem("platform");
      return {
        ...state,
        platform: null,
      };

    case "PARTITION_LOGOUT":
      localStorage.removeItem("partition");
      return {
        ...state,
        partition: null,
      };

    case "DISTRIBUTOR_LOGOUT":
      localStorage.removeItem("distributor");
      return {
        ...state,
        distributor: null,
      };

    case "STORE_LOGOUT":
      localStorage.removeItem("store");
      return {
        ...state,
        store: null,
      };
      case "KIOSK_LOGOUT":
      localStorage.removeItem("kiosk");
      return {
        ...state,
        kiosk: null,
      };
    case "UPDATE_INITIAL":
      // Store the services object in localStorage
      localStorage.setItem("services", JSON.stringify(payload.services));

      // !X! May cause bug at different tabs check later:
      localStorage.setItem("userType", payload.userType);

      return {
        ...state,
        services: {
          ...state.services,
          ...payload.services,
        },
        userType: payload.userType,
      };

    case "SET_BREADCRUMB":
      return {
        ...state,
        breadcrumb: payload.breadcrumb || [],
      };

    case "SET_DRAWER_OPEN":
      localStorage.setItem("isDrawerOpen", payload.isDrawerOpen);
      return {
        ...state,
        isDrawerOpen: payload.isDrawerOpen || false,
      };

    case "SET_PLATFORM_USER":
      return {
        ...state,
        platform_user: payload.platform_user || false,
      };

      case "SET_LANGUAGE":
        localStorage.setItem("language", payload.language);
        return {
          ...state,
          language: payload.language || "en-US",
        };
   
    default:
      return state;
  }
};

export default Reducer;
