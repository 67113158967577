import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

const DRAWER_WIDTH = 195;
const COLLAPSED_WIDTH = 0;

const DrawerStyle = styled(Drawer)(({ theme, open }) => ({
  width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH, 
  borderRight: "1px solid",
  borderColor: theme.palette.primary.light,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.lighter,
  "& .MuiDrawer-paper": {
    borderRight: "1px solid",
    borderColor: theme.palette.primary.light,
    width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
    height: "calc(100vh - 70px)",
    backgroundColor: theme.palette.primary.lighter,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: "relative",
    "&::before": {
      borderRight: "1px solid",
      borderColor: theme.palette.primary.light,
      content: '""',
      position: "absolute",
      top: 0,
      left: COLLAPSED_WIDTH,
      width: open ? DRAWER_WIDTH - COLLAPSED_WIDTH : 0,
      height: "100%",
      backgroundColor: theme.palette.primary.lighter,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));

export default function Sidebar({ isOpen, navConfig, navTitle }) {

  const {t} = useTranslation()
  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <NavSection navConfig={navConfig} isOpen={isOpen} navTitle={navTitle} />
    </Scrollbar>
  );

  return (
    <DrawerStyle variant="permanent" open={isOpen}>
      {renderContent}
    </DrawerStyle>
  );
}
