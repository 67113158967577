import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Modal, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import BaseSnackbar from "./BaseSnackbar";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { useTheme } from "@mui/material/styles";

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.common.white,
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
}));

const ModalHeaderStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  textAlign: "center",
  backgroundColor: theme.palette.neutral.darkest,
  fontSize: "24px",
  flexGrow: 1,
  alignItems: "center",
  borderRadius: "10px 10px 0px 0px",
}));

const ModalContentStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2),
  textAlign: "center",
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  fontSize: "16px",
  flexGrow: 1,
  alignItems: "center",
  borderRadius: 10,
}));

BaseModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
  informativeMessage: PropTypes.string,
};

export default function BaseModal({
  title,
  open,
  setOpen,
  children,
  informativeMessage,
  sx,
}) {
  const theme = useTheme();
  const handleClose = () => setOpen(false);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [iconButtonColor, setIconButtonColor] = useState("info");
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    let intervalId;

    if (!isClicked) {
      intervalId = setInterval(() => {
        setIconButtonColor((prevColor) =>
          prevColor === "info" ? "warning" : "info"
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [open, isClicked]);

  const handleClick = () => {
    setSnackbarStatus(!openSnackbar);
    setIsClicked(true);
    setIconButtonColor("info");
    clearInterval();
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={informativeMessage}
        setOpen={setSnackbarStatus}
        permanent={true}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="base-modal-title"
        aria-describedby="base-modal-description"
      >
        <ModalContainer sx={sx}>
          <ModalHeaderStyle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography id="base-modal-title" variant="h6" component="h2" sx={{
              color: theme.palette.neutral.light
            }}>
              {title}
            </Typography>
            {informativeMessage && (
              <Box ml={5}>
                <Tooltip title="Info">
                  <IconButton
                    color={iconButtonColor}
                    size="small"
                    aria-label="info-config"
                    onClick={() => handleClick()}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            <Box
              sx={{
                position: "absolute",
                top: -15,
                right: 5,
              }}
            >
              <Tooltip title="Minimize">
                <IconButton
                  sx={{
                    color: theme.palette.secondary.light,
                  }}
                  size="large"
                  aria-label="minimize"
                  onClick={handleClose}
                >
                  <MinimizeIcon sx={{ fontSize: "1.8rem" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </ModalHeaderStyle>

          <ModalContentStyle>
            <Typography id="base-modal-content" variant="p">
              {children ? children : "Modal Content Component"}
            </Typography>
          </ModalContentStyle>
        </ModalContainer>
      </Modal>
    </>
  );
}
