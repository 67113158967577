import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LanguageSelector from "../../components/LanguageSelector";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useStore } from "../../store/Store";
import { languageToCountry, languageToTimezone } from "../../utils/Util";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Menu, MenuItem, IconButton } from "@mui/material";

const MainStyle = styled("div")(({ theme }) => ({
  maxHeight: "70px",
  backgroundColor: theme.palette.primary.darker,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  padding: "0 16px",
  justifyContent: "space-between" 
}));

export default function KioskHeader() {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState("");
  const [country, setCountry] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [store, dispatch] = useStore();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();

    if (store.partition) {
      dispatch({ type: "PARTITION_LOGOUT" });
      navigate("/login", { replace: true });
    } else if (store.distributor) {
      dispatch({ type: "DISTRIBUTOR_LOGOUT" });
      navigate("/dt/login", { replace: true });
    } else if (store.store) {
      dispatch({ type: "STORE_LOGOUT" });
      navigate("/st/login", { replace: true });
    } else if (store.kiosk) {
      dispatch({ type: "KIOSK_LOGOUT" });
      navigate("/ki/login", { replace: true });
    }
  };

  useEffect(() => {
    const updateTime = () => {
      const timezone = languageToTimezone(store.language);
      const options = { 
        hour: '2-digit', 
        minute: '2-digit',
        /* second: '2-digit', */ //Remove comment if desired
        timeZone: timezone 
      };
      setCurrentTime(new Date().toLocaleTimeString(store.language, options));
      setCountry(languageToCountry(store.language));
    };

    updateTime();
    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, [store.language]);


  return (
    <MainStyle>
      <Box
        component={RouterLink}
        to="/"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          marginLeft: "-15px"
        }} 
      >
        <Logo sx={{
          width: 150,
          height: 60
        }}/>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
      <IconButton size="large" edge="end" onClick={handleMenu}>
          <AccountCircleIcon sx={{ color: "white" }} />
        </IconButton>
      <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Box sx={{ display: 'flex', alignItems: 'center', margin: 1.5}}>
          <AccessTimeIcon sx={{ marginRight: 0.5, marginLeft: 0.5 }} /> 
          <span>{currentTime}</span> - <span>{country}</span>
        </Box>

        <LanguageSelector />
      </Box>
    </MainStyle>
  );
}
