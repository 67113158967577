import api from "../Api";
import urls from "../Urls";

export const listGeneralFootballOdds = (params) => {
    return api.get(urls.list_football_odds, { params: params });
};

export const listCountries = (params) => {
    return api.get(urls.list_countries, { params: params });
};

export const listLeagues = (params) => {
    return api.get(urls.list_football_leagues, { params: params });
};

export const createCoupon =(payload) => {
    return api.post(urls.create_coupon, payload)
};

export const listFootballOddsDetail = (fixture_id, params) => {
    return api.get(urls.list_football_odds_detail(fixture_id), { params: params });
};

export const listBets = (params) => {
    return api.get(urls.list_bets, {params: params});
};