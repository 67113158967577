import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { PartitionsService } from "../../../../../api/services";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

export default function EditSportsBookmapping({
  formData,
  setMessage,
  setSnackbarStatus,
  successCallback,
  setModalStatus,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [bookmakerOptions, setBookmakerOptions] = useState([]);
  const [data, setData] = useState([]);
  const [searchSportQuery, setSearchSportQuery] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    let options = formData.partition_bookmakers.map((bookmaker) => ({
      value: bookmaker.bookmaker.id,
      label: bookmaker.bookmaker.name,
      selectable: bookmaker.is_active,
    }));
    setBookmakerOptions(options);

    fetchMappings();
    //eslint-disable-next-line
  }, []);

  const fetchMappings = () => {
    PartitionsService.getSportBookmakerMappings(formData.id)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
        } else {
          throw new Error("Get sports-bookmaker mappings failed");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const renderMappings = () => {
    return data
      .filter((mapping) =>
        mapping.sport.name
          .toLowerCase()
          .includes(searchSportQuery.toLowerCase())
      )
      .sort((a, b) => a.id - b.id)
      .map((mapping) => (
        <TableRow key={mapping.id}>
          <TableCell size="small">{mapping.sport.name}</TableCell>
          <TableCell size="small" align="right">
            <FormControl sx={{ width: "40%" }}>
              <Autocomplete
                className="sport-book-map-auto"
                id="combo-box-demo"
                options={bookmakerOptions}
                getOptionDisabled={(option) => !option.selectable}
                getOptionLabel={(option) => {
                  const isDisabled = !option.selectable;
                  return `${option.label}${
                    isDisabled ? " " + t("inactive-bookmaker") : ""
                  }`;
                }}
                renderInput={(params) => (
                  <TextField
                    className="sport-book-map-auto-text"
                    {...params}
                    label={
                      mapping.bookmaker?.id
                        ? t("bookmaker")
                        : t("select-bookmaker")
                    }
                  />
                )}
                value={
                  bookmakerOptions.find(
                    (option) => option.value === mapping.bookmaker?.id
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.value) {
                    handleBookmakerChange(mapping.id, newValue);
                  } else {
                    handleBookmakerChange(mapping.id, null);
                  }
                }}
              />
            </FormControl>
          </TableCell>
        </TableRow>
      ));
  };

  const handleSave = () => {
    setSubmitting(true);
    let mappings = data.map((item) => ({
      sport_id: item.sport.id,
      bookmaker_id: item?.bookmaker?.id ?? null ,
    }));

    console.log(mappings);

    let payload = {
      mappings: mappings.filter((item) => item.bookmaker_id),
    };

    let successMessage = t("sport-bookmaker-map-set-success");
    let failMessage = t("failed-to-set-sport-bookmaker-map");

    PartitionsService.updateSportBookmakerMapping(formData.id, payload)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSubmitting(false);
          }, 600);
          setMessage(successMessage);
          setSnackbarStatus(true);
          successCallback();
        } else {
          throw new Error("Failed to update sport bookmaker mapping");
        }
      })
      .catch((err) => {
        if (err.response.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setTimeout(() => {
          setSubmitting(false);
        }, 600);
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const handleBookmakerChange = (rowID, newValue) => {
    let newData = [...data];
    newData.find((item) => item.id === rowID).bookmaker = {
      id: newValue?.value,
    };
    setData(newData);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          gap: 4,
          maxHeight: "70vh",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <TextField
            placeholder={t("search-sport")}
            size="small"
            variant="standard"
            value={searchSportQuery}
            onChange={(e) => setSearchSportQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ border: "none" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("sport")}</TableCell>
                <TableCell align="right" sx={{ paddingRight: "9em" }}>
                  {t("bookmaker")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderMappings()}</TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            width: "20%",
            alignSelf: "flex-end",
            display: "flex",
            gap: "10%",
            mb: -4,
          }}
        >
          <LoadingButton
            sx={{
              backgroundColor: theme.palette.success.main,
              height: "40px",
              "&:hover": {
                backgroundColor: theme.palette.success.main_light,
              },
            }}
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSave}
            loading={isSubmitting}
            startIcon={<SaveIcon sx={{ height: "1.2rem", mr: -0.3 }} />}
          >
            {t("save")}
          </LoadingButton>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.main,
              height: "40px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main_light,
              },
            }}
            size="large"
            variant="contained"
            startIcon={<CloseIcon sx={{ height: "1.2rem", mr: -1 }} />}
            onClick={() => setModalStatus(false)}
          >
            {t("close")}
          </Button>
        </Box>
      </Card>
    </>
  );
}
