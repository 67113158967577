import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const NEUTRAL = {
  lightmost: "#F7F8F9",
  lighter: "#F2F2F2",
  light: "#D0D5DD",
  main_light: "#B8C0CC",
  main: "#A0ABBB",
  dark: "#64748B",
  darker: "#4C5768",
  darkest: "#323A46",
  main_dark: "#191D24",
  darkmost: "#0D0F11",
};

const PRIMARY = {
  lightmost: "#F2F2F2",
  lighter: "#E6E6E6",
  light: "#D9D9D9",
  main_light: "#BFBFBF",
  main: "#A6A6A6",
  dark: "#595959",
  darker: "#434343",
  darkest: "#343434",
  main_dark: "#161616",
  darkmost: "#070707",
};
const SECONDARY = {
  lightmost: "#FFF1F0",
  lighter: "#FEE4E4",
  light: "#FED6D6",
  main_light: "#FDBABC",
  main: "#C96868",
  dark: "#DE0606",
  darker: "#A70605",
  darkest: "#820404",
  main_dark: "#820A09",
  darkmost: "#690303",
};
const ACCENT = {
  lightmost: "#FFFCF3",
  lighter: "#FFF5DC",
  light: "#FFE9AE",
  main_light: "#FFD974",
  main: "#FFCF51",
  dark: "#FEBF17",
  darker: "#E8A800",
  darkest: "#BA8600",
  main_dark: "#8A6500",
  darkmost: "#8A6500",
};
const SUCCESS = {
  lightmost: "#EBFDF5",
  lighter: "#D1FAE6",
  light: "#A7F3D0",
  main_light: "#6EE7B7",
  main: "#15B392",
  dark: "#11B981",
  darker: "#069668  ",
  darkest: "#047857",
  main_dark: "#065F46",
  darkmost: "#064E3B",
};
const WARNING = {
  lightmost: "#FFFBEB",
  lighter: "#FEF3C7",
  light: "#FDE68A",
  main_light: "#FCD34D",
  main: "#FBBF24",
  dark: "#F59E0B",
  darker: "#D97706",
  darkest: "#B45309",
  main_dark: "#92400E",
  darkmost: "#78350F",
};
const ERROR = {
  lightmost: "#FEF2F2",
  lighter: "#FEE2E2",
  light: "#FECACA",
  main_light: "#FCA5A5",
  main: "#F87171",
  dark: "#EF4444",
  darker: "#DC2626",
  darkest: "#B91C1C",
  main_dark: "#991B1B",
  darkmost: "#7F1D1D",
};
const DARKMODE = {
  lightmost: "#383C41",
  lighter: "#353A3F",
  light: "#33383D",
  main_light: "#2F3338",
  main: "#2C3036",
  dark: "#272C31",
  darker: "#25292F",
  darkest: "#23272D",
  main_dark: "#1E2329",
  darkmost: "#12171D",
};
const BACKGROUND = {
  lightmost: "#F2F2F2",
  lighter: "",
  light: "",
  main_light: "",
  main: "#23272D",
  dark: "",
  darker: "",
  darkest: "",
  main_dark: "",
  darkmost: "#000",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  background: createGradient(BACKGROUND.light, BACKGROUND.main),
};

const palette = {
  common: { black: "#000", white: "#fff" },
  neutral: { ...NEUTRAL },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  accent: { ...ACCENT },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[500] },
  background: {
    ...BACKGROUND,
    paper: "#fff",
    default: "#fff",
    neutral: GREY[200],
  },
  darkmode: {
    ...DARKMODE,
    paper: "#000000",
    default: "#000000",
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
