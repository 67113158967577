import api from "../Api"
import urls from "../Urls";

export const listBookmakers = () => {
    return api.get(urls.list_bookmakers)
}

export const listSports = () => {
    return api.get(urls.list_sports)
}

export const listCurrencies = () => {
    return api.get(urls.list_currencies)
}

export const listCountries = () => {
    return api.get(urls.list_countries)
}
