import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import { PartitionsService, CommonService } from "../../../../../api/services";
import { isMobile } from "../../../../../utils/Util";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

export default function EditGeneral({
  formData,
  setMessage,
  setSnackbarStatus,
  successCallback,
  setModalStatus
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedCurrency, setSelectedCurrency] = useState(
    formData.currency_id
  );
  const [currencies, setCurrencies] = useState([]);

  const PartitionSchema = Yup.object().shape({
    partitionName: Yup.string()
      .required(t("partition-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    partitionID: Yup.number().required(t("partition-id-is-required")),

    fqdn: Yup.string().required(t("fqdn-is-required")),
    currency: Yup.number().required(t("currency-is-required")),
  });

  const formik = useFormik({
    validationSchema: PartitionSchema,
    initialValues: {
      partitionID: formData.id,
      partitionName: formData.name,
      fqdn: formData.fqdn,
      currency: formData.currency_id,
    },

    onSubmit: (values, actions) => {
      const payload = {
        id: values.partitionID,
        name: values.partitionName,
        fqdn: [values.fqdn],
        currency_id: values.currency,
      };

      let successMessage = t("partition-update-succes");
      let failMessage = t("partition-update-fail");

      PartitionsService.updatePartition(formData.id, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage(successMessage);
            setSnackbarStatus(true);
            successCallback();
            actions.setSubmitting(false);
          } else {
            throw new Error("failed to create partition");
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
          setTimeout(function () {
            actions.setSubmitting(false);
          }, 1000);
        });
    },
  });

  useEffect(() => {
    CommonService.listCurrencies()
      .then((response) => {
        if (response.status === 200) {
          let currencyOptions = response.data.map((currency) => ({
            value: currency.id,
            label: currency.name,
          }));
          setCurrencies(currencyOptions);
        } else {
          throw new Error("failed to get currencies");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              paddingBottom: 10,
              gap: 4,
              mt: 5,
            }}
          >
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                {!isMobile() && (
                  <InputLabel >
                    {t("partition-name")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitioName"
                  label={isMobile() ? t("partition-name") : undefined}
                  placeholder={t("enter-partition-name")}
                  {...getFieldProps("partitionName")}
                  error={Boolean(touched.partitionName && errors.partitionName)}
                  helperText={touched.partitionName && errors.partitionName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left"
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-id")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitionID"
                  label={isMobile() ? t("partition-id") : undefined}
                  placeholder={t("enter-partition-id")}
                  {...getFieldProps("partitionID")}
                  error={Boolean(touched.partitionID && errors.partitionID)}
                  helperText={touched.partitionID && errors.partitionID}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left"
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-fqdn")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitionFqdn"
                  label={isMobile() ? t("partition-fqdn") : undefined}
                  placeholder={t("enter-partition-fqdn")}
                  {...getFieldProps("fqdn")}
                  error={Boolean(touched.fqdn && errors.fqdn)}
                  helperText={touched.fqdn && errors.fqdn}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left"
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-currency")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <Autocomplete
                  id="combo-box-demo"
                  options={currencies}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!selectedCurrency}
                      /* label={!selectedCurrency && t("select-currency")} */
                      error={Boolean(touched.currency && errors.currency)}
                      helperText={touched.currency && errors.currency}
                      onBlur={() => formik.setFieldTouched("currency", true)}
                    />
                  )}
                  value={
                    currencies.find(
                      (option) => option.value === selectedCurrency
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const currencyValue = newValue
                      ? Number(newValue.value)
                      : null;
                    setSelectedCurrency(currencyValue);
                    formik.setFieldValue("currency", currencyValue);
                  }}
                />
              </Grid>
            </Grid>

            <Box
              className="edit-general-buttons"
              sx={{
                width: "20%",
                alignSelf: "flex-end",
                display: "flex",
                gap: "10%",
                mt: 5,
                mb: -6
              }}
            >
            <LoadingButton
                sx={{
                  backgroundColor: theme.palette.success.main,
                  height: "40px",
                  "&:hover": {
                    backgroundColor: theme.palette.success.main_light
                  }
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon sx={{height: "1.2rem", mr: -0.3}}/>}
              >
                {t("save")}
              </LoadingButton>
              <Button
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  height: "40px",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main_light
                  }
                }}
                size="large"
                variant="contained"
                startIcon={<CloseIcon sx={{height: "1.2rem", mr: -1}}/>}
                onClick={() => setModalStatus(false)}
              >
                {t("close")}
              </Button>

            </Box>
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
}
