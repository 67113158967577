import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { PlatformSettings } from "../../../api/services";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseModal from "../../../components/BaseModal";
import SaveIcon from "@mui/icons-material/Save";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import SearchBar from "../../../components/SearchBar";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import HideImageIcon from "@mui/icons-material/HideImage";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DisableAnyway from "../../../components/DisableAnyway";
import ImageLoader from "../../../components/ImageLoader";

export default function ManageCountries() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchCountry, setSearchCountry] = useState(null);
  const [searchStatus, setSearchStatus] = useState("Any");
  const [updatedCountries, setUpdatedCountries] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);
  const [openDisableAnyway, setOpenDisableAnyway] = useState(false);

  useEffect(() => {
    let result = 0;
    for (const idx in data) if (data[idx][5].value.props.checked) result++;
    setNumberOfSelectedRows(result);
  }, [data]);

  const handleSelectAll = (event) => {
    setSearchStatus("Any");
    const isChecked = event.target.checked;
    setNumberOfSelectedRows(isChecked ? data.length : 0);

    let tempData = [...data];
    let newUpdatedCountries = [];

    tempData = tempData.map((row) => {
      const countryId = row[TABLE_FIELD_MAPPING["id"].index].value;
      const currentIsEnabled =
        row[TABLE_FIELD_MAPPING["is_enabled"].index].value.props.children ===
        "True";

      if (currentIsEnabled !== isChecked) {
        newUpdatedCountries.push({
          country_id: countryId,
          is_enabled: isChecked,
        });
      }

      row[TABLE_FIELD_MAPPING["select"].index] = {
        ...row[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleRowSelect(e, countryId)}
          />
        ),
      };

      row[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...row[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: isChecked
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {isChecked ? "True" : "False"}
          </Typography>
        ),
      };

      return row;
    });

    setData(tempData);
    setFilteredData(filterCountries(tempData));
    setUpdatedCountries(newUpdatedCountries);
  };

  const TABLE_HEAD = [
    { key: "flag", label: t("flag") },
    { key: "name", label: t("country") },
    { key: "code", label: t("code") },
    { key: "enabled", label: t("enabled"), align: "center" },
    {
      key: "select",
      label: (
        <Checkbox
          checked={data.length > 0 && numberOfSelectedRows === data.length}
          indeterminate={
            numberOfSelectedRows > 0 && numberOfSelectedRows < data.length
          }
          onChange={handleSelectAll}
        />
      ),
      align: "center",
    },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    flag: { key: "flag", index: 1 },
    name: { key: "name", index: 2 },
    code: { key: "code", index: 3, paddingLeft: 4 },
    is_enabled: { key: "enabled", index: 4, align: "center", paddingRight: 4 },
    select: { key: "select", index: 5, align: "center" },
  };

  const fetchCountries = () => {
    setLoadingData(true);

    PlatformSettings.listPlatformCountries()
      .then((response) => {
        if (response.status === 200) {
          let items = [];
          for (const idx in response.data.sort(
            (a, b) => b.is_enabled - a.is_enabled
          )) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});
            const itemData = response.data[idx];

            item[TABLE_FIELD_MAPPING["select"].index] = {
              ...TABLE_FIELD_MAPPING["select"],
              value: (
                <Checkbox
                  checked={itemData.is_enabled}
                  onChange={(event) => handleRowSelect(event, itemData.id)}
                />
              ),
            };

            item[TABLE_FIELD_MAPPING["is_enabled"].index] = {
              ...TABLE_FIELD_MAPPING["is_enabled"],
              value: (
                <Typography
                  sx={{
                    color: itemData.is_enabled
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                  }}
                >
                  {itemData.is_enabled ? "True" : "False"}
                </Typography>
              ),
            };

            item[TABLE_FIELD_MAPPING["flag"].index] = {
              ...TABLE_FIELD_MAPPING["flag"],
              value: itemData.flag ? (
                <ImageLoader
                  src={itemData.flag}
                  alt={`${itemData.name} flag`}
                  width={20}
                  height={20}
                  style={{ marginRight: 8 }}
                  fallback={
                    <HideImageIcon
                      fontSize="small"
                      sx={{ mr: 0.5, color: "#2e86c1" }}
                    />
                  }
                />
              ) : (
                <PublicTwoToneIcon
                  fontSize="small"
                  sx={{ mr: 0.5, color: "#2e86c1" }}
                />
              ),
            };

            Object.entries(itemData).forEach(([key, value]) => {
              if (
                key in TABLE_FIELD_MAPPING &&
                key !== "is_enabled" &&
                key !== "flag"
              ) {
                item[TABLE_FIELD_MAPPING[key].index] = {
                  ...TABLE_FIELD_MAPPING[key],
                  value: value,
                };
              }
            });
            items.push(item);
          }
          setData(items);
          setFilteredData(items);
        } else {
          throw new Error("Failed to get countries");
        }
      })
      .catch((err) => {
        setMessage(t("failed-to-get-countries"));
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleSave = () => {
    setSubmitting(true);

    let successMessage = "success";
    let failMessage = "fail";

    const payload = {
      countries: updatedCountries,
    };

    PlatformSettings.bulkUpdatePlatfromCountries(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          setUpdatedCountries([]);
        } else {
          throw new Error("Failed to update countries");
        }
      })
      .catch((err) => {
        console.error(err);
        setTimeout(function () {
          if (
            /* "There are partitions that use this country! (England)" */
            err.response.data.detail.includes(
              "There are partitions that use this country"
            )
          ) {
            setOpenDisableAnyway(true);
            setSubmitting(false);
          } else {
            setMessage(failMessage);
            setSnackbarStatus(true);
            setSubmitting(false);
          }
        }, 800);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRowSelect = (event, countryID) => {
    setNumberOfSelectedRows(
      event.target.checked ? numberOfSelectedRows + 1 : numberOfSelectedRows - 1
    );
    setFilteredData((prevData) => {
      const tempData = [...prevData];

      const index = tempData.findIndex(
        (item) => item[TABLE_FIELD_MAPPING["id"].index]?.value === countryID
      );

      const selectedItem = tempData[index];
      const isSelected =
        selectedItem[TABLE_FIELD_MAPPING["select"].index]?.value?.props
          ?.checked;
      const isEnabled =
        selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index]?.value?.props
          ?.children === "True";

      selectedItem[TABLE_FIELD_MAPPING["select"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["select"].index],
        value: (
          <Checkbox
            checked={!isSelected}
            onChange={(event) => handleRowSelect(event, countryID)}
          />
        ),
      };

      selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index] = {
        ...selectedItem[TABLE_FIELD_MAPPING["is_enabled"].index],
        value: (
          <Typography
            sx={{
              color: !isEnabled
                ? theme.palette.success.main
                : theme.palette.warning.main,
            }}
          >
            {!isEnabled ? "True" : "False"}
          </Typography>
        ),
      };

      setUpdatedCountries((prevCountries) => {
        const indexInUpdated = prevCountries.findIndex(
          (item) => item.country_id === countryID
        );
        if (indexInUpdated === -1) {
          return [
            ...prevCountries,
            { country_id: countryID, is_enabled: !isEnabled },
          ];
        } else {
          const newCountries = [...prevCountries];
          newCountries.splice(indexInUpdated, 1);
          return newCountries;
        }
      });

      return tempData;
    });
  };

  const filterCountries = (data) => {
    return data.filter((item) => {
      const countryName = item[2].value.toLowerCase();
      const isEnabled = item[4].value.props.children === "True";

      const nameMatch =
        !searchCountry || countryName.includes(searchCountry.toLowerCase());
      const statusMatch =
        searchStatus === "Any" ||
        (searchStatus === "Enabled" && isEnabled) ||
        (searchStatus === "Disabled" && !isEnabled);

      return nameMatch && statusMatch;
    });
  };

  useEffect(() => {
    setFilteredData(filterCountries(data));
  }, [searchCountry, searchStatus]);

  const disableCallback = () => {
    setUpdatedCountries([]);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("disable-country")}
        open={openDisableAnyway}
        setOpen={setOpenDisableAnyway}
        children={
          <DisableAnyway
            successCallback={disableCallback}
            setModalStatus={setOpenDisableAnyway}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PlatformSettings.bulkUpdatePlatfromCountries(
                { countries: updatedCountries },
                {
                  is_proceed: true,
                }
              )
            }
            name={t("countries")}
            usedIn={t("partitions")}
          />
        }
      />

      <TableFilterContainer
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "6em",
          mt: "1em",
        }}
      >
        <SearchBar
          sx={{ ml: "2em" }}
          searchQuery={searchCountry}
          handleSearchChange={(e) => setSearchCountry(e.target.value)}
        />
        <FormControl sx={{ width: "10%" }}>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            label="Status"
            value={searchStatus}
            onChange={(e) => setSearchStatus(e.target.value)}
          >
            <MenuItem value={"Any"}>Any</MenuItem>
            <MenuItem value={"Enabled"}>Enabled</MenuItem>
            <MenuItem value={"Disabled"}>Disabled</MenuItem>
          </Select>
        </FormControl>

        <LoadingButton
          type="submit"
          loading={submitting}
          disabled={updatedCountries.length === 0}
          sx={{
            bgcolor:
              updatedCountries.length > 0
                ? theme.palette.success.dark
                : theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.success.main_light,
            },
            ml: "auto",
            mr: "4em",
          }}
          variant="contained"
          size="large"
          onClick={() => handleSave()}
          startIcon={<SaveIcon sx={{ height: "1.2rem", mr: -0.3 }} />}
        >
          {t("save-all")}
        </LoadingButton>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        pagination={{}}
        loadingData={loadingData}
        smallRow={true}
      />
    </>
  );
}
