import { useState } from "react";
// material
import { Box, Checkbox, Stack, Typography, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function DisableAnyway({
  setModalStatus,
  setSnackbarStatus,
  setMessage,
  successCallback,
  apiService,
  name,
  usedIn,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const deleteAnyway = () => {
    setLoading(true);

    apiService()
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          setMessage(t("disabled-success", { name }));
          successCallback();
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        } else {
          throw new Error("disable failed");
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(true);
        setTimeout(function () {
          setMessage(t("disable-fail", { name }));
          setSnackbarStatus(true);
          setModalStatus(false);
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <Paper
      elevation={6}
      sx={{
        p: 4,
        borderRadius: 2,
        backgroundColor: "background.paper",
        boxShadow: 3,
        maxWidth: 400,
        mx: "auto",
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h6">
          {t("is-using-at-disable", { name, usedIn })}
        </Typography>
        <Box
          sx={{
            alignSelf: "center",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: 'center'
          }}
        >
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
          <Typography  variant="h6" color={!checked && "primary"}>
            {t("yes-I-am-sure")}
          </Typography>
        
        </Box>
        <Stack direction="row" spacing={2} justifyContent="center">
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            disabled={!checked}
            type="submit"
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={deleteAnyway}
            sx={{
              backgroundColor: checked ? "secondary.main" : "grey.500",
              color: "white",
              "&:hover": {
                backgroundColor: checked ? "secondary.dark" : "grey.600",
              },
            }}
          >
            {t("disable")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Paper>
  );
}
