import { Typography, Link } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../store/Store";
import { useTranslation } from "react-i18next";

const RootStyle = styled("div")(({ theme }) => ({
  //Adjustments here if needed later
}));

const BreadcrumbContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "5vh",
  color: theme.palette.neutral.dark,
}));

const BreadcrumbItem = styled(Link)(({ theme }) => ({
  color: theme.palette.neutral.dark,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PageHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.darker,
  fontWeight: 600,
  marginTop: "1vh",
}));

const Separator = styled(Typography)(({ theme }) => ({
  margin: "0 8px",
  color: theme.palette.neutral.main,
}));

const isPartialPathMatch = (configPath, currentPath) => {
  const configSegments = configPath.split("/");
  const currentSegments = currentPath.split("/");

  if (configSegments.length > currentSegments.length) {
    return false;
  }

  return configSegments.every((segment, index) => segment === currentSegments[index]);
};

export default function PageLayout({ config }) {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [breadCrumbConfig, setBreadCrumbConfig] = useState([]);
  const [, dispatch] = useStore();

  useEffect(() => {
    const generateBreadCrumb = (config, pathArray = []) => {
      for (const item of config) {
        const newPathArray = [
          ...pathArray,
          { header: item.title, path: item.path },
        ];

        if (isPartialPathMatch(item.path, location.pathname)) {
          setBreadCrumbConfig(newPathArray);
          return true;
        } else if (
          item.children &&
          generateBreadCrumb(item.children, newPathArray)
        ) {
          return true;
        }
      }
      return false;
    };

    setBreadCrumbConfig([]);
    generateBreadCrumb(config);
    console.log("changed: ", breadCrumbConfig)
  }, [location, config]);

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      payload: {
        breadcrumb: breadCrumbConfig
      }
    });
  }, [breadCrumbConfig, dispatch]);

  return (
    <RootStyle className="page-layout">
      {breadCrumbConfig.length > 0 && (
        <BreadcrumbContainer>
          {breadCrumbConfig.map((crumb, index) => (
            <span key={index} style={{ display: "flex" }}>
              <BreadcrumbItem
                component="button"
                onClick={() => navigate(crumb.path)}
              >
                {t(crumb.header)}
              </BreadcrumbItem>
              {index < breadCrumbConfig.length - 1 && <Separator>/</Separator>}
            </span>
          ))}
        </BreadcrumbContainer>
      )}

      {breadCrumbConfig.length > 0 && (
        <PageHeader variant="h3">
          {t(breadCrumbConfig[breadCrumbConfig.length - 1].header)}
        </PageHeader>
      )}
    </RootStyle>
  );
}
