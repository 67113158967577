import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

import KioskHeader from "./KioskHeader";

const KioskRootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.lighter,
}));

export default function KioskLayout({ isAdminLayout = false }) {


  return (
    <KioskRootStyle>
      {/* Header Component */}
      <KioskHeader />
      <Outlet />

    </KioskRootStyle>
  );
}
