import api from "../Api"
import urls from "../Urls";

export const listPlatformCountries = (params) => {
    return api.get(urls.list_platform_countries, {params: params})
}

export const bulkUpdatePlatfromCountries = (payload, params) => {
    params = params ? params : {proceed : false};
    return api.put(urls.bulk_update_platform_countries, payload, {params})
}

export const listPlatformLeagues = (sport_id, params) => {
    return api.get(urls.list_platform_leagues(sport_id), {params: params})
}

export const bulkUpdatePlatfromLeagues = (sport_id, payload, params) => {
    params = params ? params : {proceed : false};
    return api.put(urls.bulk_update_platform_leagues(sport_id), payload, {params})
}
