import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Box, Grid, TextField, Autocomplete } from '@mui/material';
import { PartitionsService } from '../../../../api/services';
import ActiveSports from './ActiveSports';
import ActiveBookmakers from './ActiveBookmakers';
import SportBookmakerMap from './SportBookmakerMap';
import { styled } from "@mui/system";
import { TableFilterContainer } from "../../../../components/table";
import { useTranslation } from "react-i18next";

export default function ManagePartitions() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [partitions, setPartitions] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState(null);
  const { t } = useTranslation();


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  const fetchPartitions = () => {
    PartitionsService.listPartitions()
      .then((response) => {
        if (response.status === 200) {
          let options = response.data.items.map((partition) => ({
            value: partition.id,
            label: partition.name,
            bookmakers: partition.partition_bookmakers,
            sports: partition.partition_sports
          }));
          setPartitions(options);
        } else {
          throw new Error("Failed to list partitions");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPartitions();
 
  }, [selectedTab, selectedPartition]);

  return (
    <>
     <TableFilterContainer sx={{marginTop: "2vh" }}>
        <Grid sx={{ alignItems: "center" }} container spacing={4}>
          <Grid item md={4} xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={partitions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("select-partition")}
                />
              )}
              value={
                partitions.find(
                  (option) => option.value === selectedPartition
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedPartition(newValue?.value || null);
              }}
            />
          </Grid>
        </Grid>
      </TableFilterContainer>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs" sx={{pt: 3}}>
        <Tab label="Active Sports" />
        <Tab label="Active Bookmakers" />
        <Tab label="Bookmaker Map" />
      </Tabs>
   <Box sx={{ p: 3 }}>
  {selectedTab === 0 && (
    <ActiveSports
      partition={partitions.find(p => p.value === selectedPartition)}
      selectedTab={selectedTab}
    />
  )}
  {selectedTab === 1 && (
    <ActiveBookmakers
      partition={partitions.find(p => p.value === selectedPartition)}
      selectedTab={selectedTab}
    />
  )}
  {selectedTab === 2 && (
    <SportBookmakerMap
      partition={partitions.find(p => p.value === selectedPartition)}
      selectedTab={selectedTab}
    />
  )}
</Box>

    </>
  );
};

