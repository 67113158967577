import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField, Typography, Box, Grid, Button } from "@mui/material";
import { color, styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../store/Store";
import BaseSnackbar from "../../BaseSnackbar";
import { AuthenticationService } from "../../../api/services";
import { useTheme } from "@mui/material/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import palette from "../../../theme/palette";

const CustomTextField = styled(TextField)(({ theme }) => ({
"& input": {
    color: palette.neutral.lightmost,
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "10px 0",
  },
  height: "10vh",
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInput-underline:after": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInputLabel-formControl": {
    fontWeight: "bold",
    marginTop: "1rem",
  },

  "& .MuiInputLabel-root": {
    transform: "translate(0, 0px) scale(1)",
    transition: "all 0.2s ease-out",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -23px) scale(1)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.92rem",
    color: palette.secondary.dark,
    fontWeight: "bold",
    marginTop: "8px",
  },}));

export default function KioskLoginForm({loginType}) {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [, dispatch] = useStore();
  const theme = useTheme();

  const fetchSettings = async (loginResponse) => {
    
    const loginPayload = {
      token: loginResponse.data.access_token,
      expires_at: loginResponse.data.expires_at,
      refresh_token: loginResponse.data.refresh_token,
      refresh_token_expires_at: loginResponse.data.refresh_token_expires_at,
      refresh_token_issued_at: loginResponse.data.refresh_token_issued_at,
      user: {
        id: loginResponse.data.user_info.id,
        first_name: loginResponse.data.first_name,
        last_name: loginResponse.data.last_name,
      },
      
    };

    const payloadType = {
      kiosk: "kiosk",
    }[loginType];

    dispatch({
      type: "LOGIN",
      payload: {
        [payloadType]: loginPayload,
      },
    });

    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        //language: loginResponse.data.user.selected_language_code,
        language: "en-US",
      },
    });
  };

  // const getFormData = (values) => {
  //   const formData = new FormData();
  //   formData.append("access_token", values.access_token);
  //   return formData;
  // };
  const LoginSchema = Yup.object().shape({
    access_token: Yup.string().required("Key is required"),
  });

  const getFormData = (values) => {
    const formData = new FormData();
    formData.append("password", values.access_token);
    return formData;
  };

  const formik = useFormik({
    initialValues: {
      access_token: "",
    },

    
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, setTouched }) => {
      setIsSubmitted(true);
      setSubmitting(true);
      if (values.access_token === "") {
        setSubmitting(false);
        setTouched({
          access_token: true,
        });
        return;
      }
      const payload = getFormData(values);

      let apiService;

      switch (loginType) {
        case "kiosk":
          apiService = AuthenticationService.kioskLogin(payload);
          break;
        default:
            throw new Error(`Unexpected loginType: ${loginType}`);
      }

      apiService
        .then((response) => {
          if (response.status === 200) {
            setLoginMessage(t("login-success"));
            setOpenSnackbar(true);
            fetchSettings(response);
          } else {
            throw new Error("Failed to login");
          }
        })
        .catch((err) => {
          console.error("API Error:", err); 
          setLoginMessage(t("login-fail"));
          setOpenSnackbar(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={loginMessage}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box>
            <Box display="flex" flexGrow={1} flexDirection={{ xs: 'column', md: 'row' }}>
              {/* Left Side QR Code Button */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flexBasis={{ xs: '100%', md: '40%' }} 
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: "bold",
                    backgroundColor: palette.accent.dark,
                    "&:hover": {
                      backgroundColor: palette.accent.main, 
                    },
                    color: palette.darkmode.light,
                  }}
                >
                  or Login With QR Code
                </Button>
              </Box>

              {/* Right Side Input Form and Keypad */}
              <Stack spacing={3} flexGrow={1} alignItems="center" flexBasis={{ xs: '100%', md: '40%' }}>
                <Box width="70%">
                  <CustomTextField
                    variant="standard"
                    fullWidth
                    label={
                      <Typography color={theme.palette.accent.main} variant="h4s">
                        Enter KEY
                      </Typography>
                    }
                    {...getFieldProps("access_token")}
                    error={Boolean(touched.access_token && errors.access_token)}
                    helperText={touched.access_token && errors.access_token}
                  />
                </Box>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '90%'}}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
                    <Grid item xs={4} sm={4} key={num}>
                      <Button
                        variant="contained"
                        onClick={() => formik.setFieldValue('access_token', formik.values.access_token + num)}
                        sx={{
                          width: '100%',
                          height: '60px',
                          fontSize: '1.5rem',
                          backgroundColor: palette.grey[0],
                          borderRadius: 2,
                          '&:hover': { backgroundColor: palette.grey[300] },
                        }}
                      >
                        {num}
                      </Button>
                    </Grid>
                  ))}
                  <Grid item xs={4} sm={4}>
                    <Button
                      variant="contained"
                      onClick={() => formik.setFieldValue('access_token', formik.values.access_token.slice(0, -1))}
                      sx={{
                        width: '100%',
                          height: '60px',
                          fontSize: '1.2rem',
                          backgroundColor: palette.grey[0],
                          borderRadius: 2,
                          '&:hover': { backgroundColor: palette.grey[300] },
                      }}
                      endIcon={<BackspaceIcon sx={{color:palette.grey[500]}}/>}
                    >
                      Del 
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Button
                      variant="contained"
                      onClick={() => formik.setFieldValue('access_token', '')}
                      sx={{
                        width: '100%',
                          height: '60px',
                          fontSize: '1.2rem',
                          backgroundColor: palette.grey[0],
                          borderRadius: 2,
                          '&:hover': { backgroundColor: palette.grey[300] },
                      }}
                      endIcon = {<DeleteIcon sx={{color:palette.grey[500]}}/>}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                    sx={{
                      width:"70%",
                      mt: 2,
                      fontSize: '1.5rem',
                      backgroundColor: palette.accent.dark,
                      "&:hover": {
                        backgroundColor: palette.accent.main, 
                      },
                      color: palette.darkmode.light,
                    }}
                  >
                    {t("login")}
                  </LoadingButton>
              </Stack>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
