import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/store/LandingPage';
import ListStoreUsers from './pages/store/users/ListStoreUser';
import AddNewStoreUser from './pages/store/users/addStoreUser/addStoreUser';
import ListBets from './pages/store/bets/ListBets';
import ListCoupons from './pages/store/coupons/ListCoupons';

export default function StoreRouter() {
  const [store, dispatch] = useStore();
  const storeUser = JSON.parse(localStorage.getItem("store"))

 
  const handleAppRedirect = () => {
    if (isTokenExpired(storeUser?.expires_at)) {
      (async () => {
        await dispatch({ type: "STORE_LOGOUT" });
      })();
    }

    if (storeUser !== null && store?.storeUser?.token !== null) {
        return <MainLayout isAdminLayout={true}/>;
    } else {
      return <Navigate to="/st/login" />;
    }
  };
  return useRoutes([
    {
      path: "/st",
      element: handleAppRedirect(),
      children: [
        { path: "/st", index: true, element: <Navigate to="/st/landing" replace /> },
        { path: "/st/landing", element: <LandingPage /> },
        { path: "/st/store-users", element: <Navigate to="/st/store-users/list-users" /> },
        { path: "/st/store-users/list-users", element: <ListStoreUsers /> },
        { path: "/st/store-users/add-new-user", element: <AddNewStoreUser /> },
        { path: "/st/bets", element: <ListBets /> },
        { path: "/st/coupons", element: <ListCoupons /> },
      ]
    },
    {
      path: "/st/login",
      element: (storeUser === null || storeUser?.token === null ) ? <Auth type="store-login" /> : <Navigate to="/st"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
