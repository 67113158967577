import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, TextField, Box, IconButton} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DefaultPaginationData } from "../../../constants";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { OddsService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import ClearIcon from "@mui/icons-material/Clear";

export default function ListBets() {
  const { t } = useTranslation();
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [sortState, setSortState] = useState({
    bet_amount: "ASC",
    created_at: "ASC"
  });
  const [couponStatusFilter, setCouponStatusFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const sorting = (field) => {
    const fieldIndex = TABLE_HEAD.findIndex(header => header.key === field);
    
    setSortState(prev => ({
      ...prev,
      [field]: prev[field] === "ASC" ? "DESC" : "ASC"
    }));

    const isAscending = sortState[field] === "ASC";
    
    let sorted;
    if (field === "bet_amount") {
      sorted = [...data].sort((a, b) => {
        const aValue = parseFloat(a[fieldIndex].value) || 0;
        const bValue = parseFloat(b[fieldIndex].value) || 0;
        return isAscending ? aValue - bValue : bValue - aValue;
      });
    } else if (field === "created_at") {
      sorted = [...data].sort((a, b) => {
        const aValue = new Date(a[fieldIndex].value).getTime();
        const bValue = new Date(b[fieldIndex].value).getTime();
        return isAscending ? aValue - bValue : bValue - aValue;
      });
    }
    
    setData(sorted);
  };

  const TABLE_HEAD = [
    { key: "id", label: t("bet-id") },
    { 
      key: "created_at", 
      label: (
        <div className="flex items-center cursor-pointer gap-1" onClick={() => sorting("created_at")}>
          {t("created-at")}
          {sortState.created_at === "ASC" ? (
            <ArrowUpwardIcon fontSize="small" color="accent" sx={{ml:1}} />
          ) : (
            <ArrowDownwardIcon fontSize="small" color="accent" sx={{ml:1}} />
          )}
        </div>
      )
    },
    { key: "coupon_status", label: t("coupon-status") },
    { key: "fixture_id", label: t("fixture-id") },
    { key: "odd_id", label: t("odd-id") },
    { 
      key: "bet_amount", 
      label: (
        <div className="flex items-center cursor-pointer gap-1" onClick={() => sorting("bet_amount")}>
          {t("bet-amount")}
          {sortState.bet_amount === "ASC" ? (
            <ArrowUpwardIcon fontSize="small" color="accent" sx={{ml:1}}/>
          ) : (
            <ArrowDownwardIcon fontSize="small" color="accent" sx={{ml:1}} />
          )}
        </div>
      )
    },
  ];

  const formatDate = (date) => {
    return new Date(date).toISOString(); 
  };
  
  const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(date);
};
  const couponStatusMap = {
    1: "Pending Payment",
    2: "Won - Payout Due",
    3: "Closed - No Payout",
    4: "Closed - Payout Complete",
  };
  
  const fetchBets = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
      ...(couponStatusFilter && { coupon_status: couponStatusFilter }),
      ...(startDate && { start_date: formatDate(startDate) }),
      ...(endDate && { end_date: formatDate(endDate) }),
    };
  
    OddsService.listBets(params)
      .then((response) => {
        const items = response.data.items.map((item) => [
          { key: `id-${item.id}`, value: item.id },
          { key: `created_at-${item.id}`, value: formatDateTime(item.created_at) },  
          {
            key: `coupon_status-${item.id}`,
            value: couponStatusMap[item.coupon_status] || "Unknown Status",
          },
          {
            key: `fixture_id-${item.id}`,
            value: item.placed_bets?.[0]?.fixture_id || "-",
          },
          { key: `odd_id-${item.id}`, value: item.placed_bets?.[0]?.odd_id || "-" },
          { key: `bet_amount-${item.id}`, value: item.placed_bets?.[0]?.bet_amount || 0 },
        ]);
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-bets"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };
  
  
  useEffect(() => {
    fetchBets();
  }, [paginationData, couponStatusFilter, startDate, endDate]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    const lowerQuery = query.toLowerCase();
    const statusId = Object.keys(couponStatusMap).find(
      (key) => couponStatusMap[key].toLowerCase() === lowerQuery
    );

    return data.filter((row) => {
      return (
        ["id", "created_at", "fixture_id", "odd_id"].some((key, idx) => {
          const cellValue = row[idx]?.value.toString().toLowerCase() || "";
          return cellValue.includes(lowerQuery);
        }) ||
        (statusId && row[2].value === couponStatusMap[statusId])
      );
    });
  };

  const clearFilters = () => {
    setCouponStatusFilter("");
    setStartDate("");
    setEndDate("");
    setSearchQuery("");
  };

  const filteredData = filterData(data, searchQuery);

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Select
            value={couponStatusFilter}
            onChange={(event) => setCouponStatusFilter(event.target.value)}
            displayEmpty
            sx={{ mt: 1, mr: 2 }}
          >
            <MenuItem value="">{t("all-statuses")}</MenuItem>
            {Object.entries(couponStatusMap).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(event) => setStartDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mr: 2 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(event) => setEndDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mr: 2 }}
          />
          <IconButton onClick={clearFilters} sx={{ mt: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}