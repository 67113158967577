import React, { useState, useEffect } from 'react';

// Queue for managing image requests
class RequestQueue {
  constructor(concurrency = 3, retryDelay = 1000) {
    this.queue = [];
    this.running = 0;
    this.concurrency = concurrency;
    this.retryDelay = retryDelay;
  }

  async add(url, onComplete) {
    this.queue.push({ url, onComplete });
    this.process();
  }

  async process() {
    if (this.running >= this.concurrency || this.queue.length === 0) {
      return;
    }

    this.running++;
    const { url, onComplete } = this.queue.shift();

    try {
      const response = await fetch(url, { method: 'HEAD' });
      
      if (response.status === 429) {
        // If rate limited, wait and retry
        await new Promise(resolve => setTimeout(resolve, this.retryDelay));
        this.queue.push({ url, onComplete });
      } else {
        onComplete(url);
      }
    } catch (error) {
      console.error('Error loading image:', error);
      onComplete(null);
    }

    this.running--;
    this.process();
  }
}

// Singleton queue instance
const globalQueue = new RequestQueue();

// Main ImageLoader component
export default function ImageLoader({ 
  src, 
  alt = '', 
  fallback = null,
  className = '',
  width = 20,
  height = 20,
  style = {}
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!src) {
      setLoading(false);
      setError(true);
      return;
    }

    setLoading(true);
    globalQueue.add(src, (resolvedSrc) => {
      if (resolvedSrc) {
        setImageSrc(resolvedSrc);
        setError(false);
      } else {
        setError(true);
      }
      setLoading(false);
    });
  }, [src]);

  const defaultStyle = {
    width,
    height,
    ...style
  };

  if (loading) {
    return (
      <div 
        style={{
          ...defaultStyle,
          backgroundColor: '#f3f4f6',
          borderRadius: '4px'
        }}
        className={`animate-pulse ${className}`}
      />
    );
  }

  if (error || !imageSrc) {
    if (fallback) return fallback;
    
    return (
      <div 
        style={{
          ...defaultStyle,
          backgroundColor: '#f3f4f6',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        className={className}
      >
        <svg 
          style={{
            width: Math.min(width, height) * 0.6,
            height: Math.min(width, height) * 0.6
          }}
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
          />
        </svg>
      </div>
    );
  }

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      style={defaultStyle}
    />
  );
}