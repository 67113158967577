import { useState, useEffect } from "react";
import { BaseTable } from "../../../../components/table";
import { Box, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { PartitionsService } from "../../../../api/services";
import BaseSnackbar from "../../../../components/BaseSnackbar";
import DisableAnyway from "../../../../components/DisableAnyway";
import BaseModal from "../../../../components/BaseModal";

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: "3vh",
  backgroundColor: theme.palette.neutral.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function ActiveBookmakers({ partition, selectedTab }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [message, setMessage] = useState([]);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [openDisableAnyway, setOpenDisableAnyway] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [activeBookmakersPayload, setActiveBookmakersPayload] = useState([]);

  const TABLE_HEAD = [
    { key: "bookmaker", label: t("bookmaker") },
    { key: "enabled", label: t("Enabled"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0, noRender: true },
    bookmaker: { key: "bookmaker", index: 1 },
    is_active: { key: "enabled", index: 2 },
    bookmaker_id: { key: "bookmaker_id", index: 3, noRender: true },
  };

  const fetchBookmakers = () => {
    if (partition && partition.bookmakers) {
      setLoadingData(true);
      let partitionBookmakerData = partition.bookmakers.sort((a, b) => a.id - b.id);
      let items = [];
      for (const idx in partitionBookmakerData) {
        let item = new Array(TABLE_HEAD.length - 1).fill({});
        const itemData = partitionBookmakerData[idx];

        if (itemData.bookmaker) {
          let bookmakerName = itemData.bookmaker?.name;
          item[TABLE_FIELD_MAPPING["bookmaker"].index] = {
            ...TABLE_FIELD_MAPPING["bookmaker"],
            value: bookmakerName,
          };
          let bookmakerID = itemData.bookmaker?.id;
          item[TABLE_FIELD_MAPPING["bookmaker_id"].index] = {
            ...TABLE_FIELD_MAPPING["bookmaker_id"],
            value: bookmakerID,
          };
        }

        Object.entries(itemData).forEach(([key, value]) => {
          if (key in TABLE_FIELD_MAPPING && key !== "bookmaker") {
            item[TABLE_FIELD_MAPPING[key].index] = {
              ...TABLE_FIELD_MAPPING[key],
              value: value,
            };
          }
        });
        items.push(item);
      }
      setData(items);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (partition && selectedTab === 1) {
      fetchBookmakers();
    }
  }, [partition, selectedTab]);

  const handleOnChangeEnabled = (index) => {
    let activeBookmakers = data
      .filter((row) => row.find((item) => item.key === "enabled" && item.value))
      .map((row) => row.find((item) => item.key === "bookmaker_id").value);

    let successMessage = data[index][2].value
      ? t("bookmaker-disabled-successfully")
      : t("bookmaker-enabled-successfully");

    let failMessage = data[index][2].value
      ? t("bookmaker-fail-disable")
      : t("fail-enable-bookmaker");

    if (data[index][2].value) {
      activeBookmakers = activeBookmakers.filter(
        (item) => item !== data[index][3].value
      );
    } else {
      activeBookmakers.push(data[index][3].value);
    }
    setActiveBookmakersPayload(activeBookmakers);
    let payload = {
      active_bookmakers: activeBookmakers,
    };
    PartitionsService.updatePartition(partition.value, payload)
      .then((response) => {
        if (response.status === 200) {
          let newData = [...data];
          newData[index][2].value = !data[index][2].value;
          setData(newData);
          setMessage(successMessage);
          setSnackbarStatus(true);
        } else {
          throw new Error("Failed to deactivate bookmaker!");
        }
      })
      .catch((err) => {
        setTimeout(function () {
          if (
            err.response.data.detail ===
            "Failed to deactivate bookmaker. Bookmaker assigned to a sport!"
          ) {
            setSelectedIndex(index);
            setOpenDisableAnyway(true);
          } else {
            setMessage(failMessage);
            setSnackbarStatus(true);
          }
        }, 800);
      });
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        color="success"
        checked={data[index][2].value}
        onChange={() => handleOnChangeEnabled(index)}
      />
    );
  };

  const disableCallback = () => {
    let newData = [...data];
    newData[selectedIndex][2].value = !data[selectedIndex][2].value;
    setData(newData);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("disable-bookmaker")}
        open={openDisableAnyway}
        setOpen={setOpenDisableAnyway}
        children={
          <DisableAnyway
            successCallback={disableCallback}
            setModalStatus={setOpenDisableAnyway}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PartitionsService.updatePartition(
                partition.value,
                { active_bookmakers: activeBookmakersPayload },
                {
                  proceed: true,
                }
              )
            }
            name={t("bookmaker")}
            usedIn={t("sport-bookmaker-mapping")}
          />
        }
      />
      {partition ? (
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          pagination={{}}
          loadingData={loadingData}
          enableSwitch={enableSwitch}
          alignSwitch={"right"}
        />
      ) : (
        <StyledBox>Please select partition</StyledBox>
      )}
    </>
  );
}
