import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Chip, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import { useTheme } from "@mui/material/styles";
/* import { convertUTCToLocalTime } from '../utils/Util'; */
import { useStore } from '../store/Store';
export default function GeneralOdds({ data, addToCoupon, removeFromCoupon, couponBets, onMoreClick }) {
  const theme = useTheme();
  const [store] = useStore();
  const { league, fixture, bets } = data;
  /* const localFixtureTime = convertUTCToLocalTime(fixture.date, fixture.time, store.language);
  const fixtureDateTime = new Date(`${fixture.date}T${localFixtureTime}`); */
  const fixtureDateTime = new Date(`${fixture.date}T${fixture.time}`);

  const [selectedOdd, setSelectedOdd] = useState(null);

  const handleAddToCoupon = (selectedBet, odd_id) => {
    const existingBet = getExistingBet();
  
    if (isOddSelected(odd_id)) {
      removeFromCoupon(existingBet);
      setSelectedOdd(null); // Reset selected odd to remove color
    } else {
      if (existingBet) {
        removeFromCoupon(existingBet); // Remove existing bet before adding a new one
      }
      setSelectedOdd(odd_id); 
      addToCoupon({ fixture, selectedBet, odd_id }); 
    }
  };

  const isOddSelected = (odd_id) => selectedOdd === odd_id;
  
  const getExistingBet = () => 
    couponBets.find(b => b.fixture.fixture_id === fixture.fixture_id);

  useEffect(() => {
    // Reset selectedOdd if the bet for this fixture is removed from the coupon
    if (!getExistingBet()) {
      setSelectedOdd(null);
    }
  }, [couponBets]);

  return (
    <Card sx={{ mx: 'auto', my: 2, boxShadow: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} sx={{ backgroundColor: theme.palette.primary.lightmost, padding: '0.5rem' }}>
        <Box display="flex" alignItems="center">
          {league.flag ? (
            <img 
              src={league.flag} 
              alt={`${league.country} flag`} 
              style={{ width: 20, height: 20, marginRight: 8 }} 
            />
          ) : (
            <PublicTwoToneIcon
              fontSize="small"
              sx={{ mr: 0.5, color: '#2e86c1' }}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <Typography variant="caption" color="text.primary">
              {league.country}
            </Typography> 
            <Typography variant="caption" color="text.secondary">
              {league.name}
            </Typography> 
          </Box>
        </Box>
        <Typography variant="caption" color="text.secondary">
          {fixtureDateTime.toLocaleString('en-US', { 
            month: 'numeric', 
            day: 'numeric', 
            year: 'numeric', 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true 
          })}
        </Typography>
      </Box>

      <CardContent sx={{ p: 2, display: "flex", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'space-around' } }}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={1.5}>
    <img 
      src={fixture.home_team_logo} 
      alt={`${fixture.home_team} logo`} 
      style={{ width: 25, height: 25, marginRight: 8 }} 
    />
    <Typography variant="body1" fontWeight="medium">{fixture.home_team}</Typography>
    <Typography variant="caption" color="text.secondary" mx={1}>vs</Typography>
    <Typography variant="body1" fontWeight="medium">{fixture.away_team}</Typography>
    <img 
      src={fixture.away_team_logo} 
      alt={`${fixture.away_team} logo`} 
      style={{ width: 25, height: 25, marginLeft: 8 }} 
    />
  </Box>

        {bets && (
          <Box display="flex" justifyContent="center" alignItems="center">
            {bets.values.map((value, index) => (
              <Box key={index} mx={{xs:0.2, md:1}}>
                <Chip
                  label={
                    <Box>
                      <Typography variant="caption" display="block">{value.value}</Typography>
                      <Typography variant="body2" fontWeight="bold">{value.odd}</Typography>
                    </Box>
                  }
                  variant="outlined"
                  sx={{
                    minWidth:{xs:20, md:70},
                    backgroundColor: selectedOdd === value.odd_id ? '#16a085' : theme.palette.accent.lighter,  
                  }}
                  onClick={() => handleAddToCoupon(value.odd, value.odd_id)} 
                />
              </Box>
            ))}
          </Box>
        )}
      </CardContent>

      <Box
        sx={{
          backgroundColor: '#2c2c2c',
          color: '#fff',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
       <Link component="button" sx={{ color: theme.palette.accent.main, paddingRight: "15px" }} onClick={() => onMoreClick(fixture.fixture_id)}>
        More
      </Link>
      </Box>
    </Card>
  );
}
