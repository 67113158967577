import React, { useEffect, useState } from "react";
import { Box, Grid, TablePagination, Typography } from "@mui/material";
import SelectedFilters from "../../layouts/kiosk/SelectedFilters";
import FilterComponent from "../../layouts/kiosk/Filter";
import { OddsService } from "../../api/services";
import GeneralOdds from "../../components/GeneralOdds";
import BetCoupon from "../../layouts/kiosk/BetCoupon";
import SportsFilter from "../../layouts/kiosk/SportsFilter";
import OddsDetail from "../../layouts/kiosk/OddsDetail";

export default function LandingPage() {
  const [filters, setFilters] = useState({
    sports: [],
    countries: [],
    leagues: [],
  });
  const [odds, setOdds] = useState([]);
  const [bets, setBets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedFixtureId, setSelectedFixtureId] = useState(null);

  const fetchFootballOdds = (page, rowsPerPage) => {
    let currentDate = new Date();
    let start_date = currentDate.toISOString();
    let end_date = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000).toISOString();
    let country_ids = JSON.stringify(filters.countries.map((country) => country.id));
    let league_ids = JSON.stringify(filters.leagues.map((league) => league.id));

    let params = {
      start_date,
      end_date,
      page: page + 1,
      limit: rowsPerPage,
      country_ids,
      league_ids,
    };

    OddsService.listGeneralFootballOdds(params)
      .then((response) => {
        if (response.status === 200) {
          setOdds(response.data.items);
          setTotalItems(response.data.total);
        } else {
          throw new Error("Failed to fetch odds");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchFootballOdds(page, rowsPerPage);
  }, [filters, page, rowsPerPage]);

  const handleMoreClick = (fixtureId) => {
    setSelectedFixtureId(fixtureId); 
  };

  const handleBackToGeneralOdds = () => {
    setSelectedFixtureId(null); 
  };

  const addToCoupon = (newBet) => {
    setBets((prevBets) => {
      const existingBetIndex = prevBets.findIndex(
        (bet) => bet.fixture.fixture_id === newBet.fixture.fixture_id
      );

      if (existingBetIndex !== -1) {
        const updatedBets = [...prevBets];
        updatedBets[existingBetIndex] = newBet;
        return updatedBets;
      }

      return [...prevBets, newBet];
    });
  };

  const removeBet = (betToRemove) => {
    setBets((prevBets) =>
      prevBets.filter((bet) => bet.fixture.fixture_id !== betToRemove.fixture.fixture_id)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box>
        <SelectedFilters filters={filters} />
      </Box>
      <Grid container spacing={{ xs: 2, md: 10 }}>
        <Grid item xs={3} md={3}>
          <Box>
            <FilterComponent setFilters={setFilters} />
          </Box>
        </Grid>

        <Grid item xs={6} md={6}>
          <Box
            sx={{
              backgroundColor: "white",
              paddingX: "2rem",
              borderRadius: "1rem",
            }}
          >
            <Box mb={2}>
              <SportsFilter setFilters={setFilters} />
            </Box>

            {selectedFixtureId ? (
              <OddsDetail
                fixture_id={selectedFixtureId}
                onBack={handleBackToGeneralOdds}
                addToCoupon={addToCoupon}
                removeFromCoupon={removeBet}
                couponBets={bets}
              />
            ) : (
              <>
                {odds.map((odd, index) => (
                  <GeneralOdds
                    key={index}
                    data={odd}
                    addToCoupon={addToCoupon}
                    removeFromCoupon={removeBet}
                    couponBets={bets}
                    onMoreClick={handleMoreClick}
                  />
                ))}
                <Box display="flex" justifyContent="center" mt={2}>
                  <TablePagination
                    component="div"
                    count={totalItems}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[20, 40, 60]}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={3} md={3}>
          <BetCoupon bets={bets} removeBet={removeBet} />
        </Grid>
      </Grid>
    </>
  );
}
