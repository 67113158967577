import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, FormControlLabel, Checkbox, Button, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useTranslation } from "react-i18next";
import { OddsService, CommonService } from "../../api/services";
import SearchBar from "../../components/SearchBar";
import { getPopularCountries, getPopularLeagues } from "../../constants";

const FilterComponent = ({ setFilters }) => {
  const { t } = useTranslation();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [countrySearchQuery, setCountrySearchQuery] = useState('');
  const [leagueSearchQuery, setLeagueSearchQuery] = useState('');
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredLeagues, setFilteredLeagues] = useState([]);

  // Fetch countries data
  useEffect(() => {
    CommonService.listCountries()
      .then((response) => {
        if (response.status === 200) {
          const combinedCountries = [
            ...getPopularCountries,
            ...response.data.filter(
              country => !getPopularCountries.some(pop => pop.id === country.id)
            )
          ];
          setCountries(combinedCountries);
          setFilteredCountries(combinedCountries);
        } else {
          throw new Error("failed to get countries");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    OddsService.listLeagues()
      .then((response) => {
        if (response.status === 200) {
          const combinedLeagues = [
            ...getPopularLeagues,
            ...response.data.filter(
              league => !getPopularLeagues.some(pop => pop.id === league.id)
            )
          ];
          setLeagues(combinedLeagues);
          setFilteredLeagues(combinedLeagues);
        } else {
          throw new Error("failed to get leagues");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setFilters({
      countries: selectedCountries,
      leagues: selectedLeagues,
      dateRange: selectedDates,
    });
  }, [selectedCountries, selectedLeagues, selectedDates, setFilters]);

  const handleCountriesChange = (countryId) => {
    setSelectedCountries((prev) => {
      const updatedCountries = prev.includes(countryId)
        ? prev.filter((id) => id !== countryId)
        : [...prev, countryId];
        
      setFilters((prevFilters) => ({
        ...prevFilters,
        countries: updatedCountries,
      }));
  
      return updatedCountries;
    });
  };
  
  
 const handleLeaguesChange = (leagueId) => {
  setSelectedLeagues((prev) => {
    const updatedLeagues = prev.includes(leagueId)
      ? prev.filter((id) => id !== leagueId)
      : [...prev, leagueId];    
  
    setFilters((prevFilters) => ({
      ...prevFilters,
      leagues: updatedLeagues,
    }));
    
    return updatedLeagues; 
  });
};
  

  const handleDateChange = (event) => {
    setSelectedDates({
      ...selectedDates,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setFilteredCountries(
      countries.filter(country =>
        country.name.toLowerCase().includes(countrySearchQuery.toLowerCase())
      )
    );
  }, [countrySearchQuery, countries]);

  useEffect(() => {
    setFilteredLeagues(
      leagues.filter(league =>
        league.name.toLowerCase().includes(leagueSearchQuery.toLowerCase())
      )
    );
  }, [leagueSearchQuery, leagues]);

  return (
    <Box
      sx={{
        padding: "3px",
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "#FFFF",
        minHeight: "47rem",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px", margin: { xs: 0, md: 2 } }}>
        <FilterAltOutlinedIcon style={{ fontSize: "25px" }} />
        <Typography variant="h6">{t("Filter By")}</Typography>
      </Box>

      <Accordion sx={{ backgroundColor: "#F2F2F2", borderRadius: "1rem", margin: "0.5rem" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: { xs: '10px', md: '15px' } }}>{t("Countries")}</Typography>
        </AccordionSummary>
        <SearchBar 
          searchQuery={countrySearchQuery}
          handleSearchChange={(e) => setCountrySearchQuery(e.target.value)}
        />
        <AccordionDetails>
          {filteredCountries.slice(0, 6).map((country) => (
            <FormControlLabel
              key={country.id}
              control={
                <Checkbox
                  checked={selectedCountries.includes(country)}
                  onChange={() => handleCountriesChange(country)}
                />
              }
              label={t(country.name)}
            />
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: "#F2F2F2", borderRadius: "1rem", margin: "0.5rem" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: { xs: '10px', md: '15px' } }}>{t("Leagues")}</Typography>
        </AccordionSummary>
        <SearchBar 
          searchQuery={leagueSearchQuery}
          handleSearchChange={(e) => setLeagueSearchQuery(e.target.value)}
        />
        <AccordionDetails>
          {filteredLeagues.slice(0, 6).map((league) => (
            <FormControlLabel
              key={league.id}
              control={
                <Checkbox
                  checked={selectedLeagues.includes(league)}
                  onChange={() => handleLeaguesChange(league)}
                />
              }
              label={t(league.name)}
            />
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ backgroundColor: "#F2F2F2", borderRadius: "1rem", margin: "0.5rem" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: { xs: '10px', md: '15px' } }}>{t("Date")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            control={
              <Checkbox
                name="today"
                checked={selectedDates.today}
                onChange={handleDateChange}
              />
            }
            label={t("Today")}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="tomorrow"
                checked={selectedDates.tomorrow}
                onChange={handleDateChange}
              />
            }
            label={t("Tomorrow")}
          />
        </AccordionDetails>
      </Accordion>

      <Button
        variant="contained"
        color="accent"
        fullWidth
        sx={{ marginTop: "20px" }}
      >
        {t("Apply")}
      </Button>
    </Box>
  );
};

export default FilterComponent;
